import React from "react";

import MediaSlider from "../../../components/MediaSlider";

const AuctionMedia: React.FC<{
  auction: Pick<
    Queries.AuctionContentFragment,
    "name" | "pictureMetadata" | "thumbnailMetadata" | "mapMetadata"
  >;
}> = ({ auction }) => (
  <div className="max-w-screen-xl mx-auto lg:flex lg:items-start lg:justify-between px-4 mb-20">
    <MediaSlider
      className="pb-12 lg:pb-0 lg:w-6/12"
      pictures={auction.pictureMetadata}
      thumbnails={auction.thumbnailMetadata}
    />
    {auction.mapMetadata && (
      <img
        alt={`Map of ${auction.name}`}
        className="w-full lg:w-5/12 h-auto"
        src={auction.mapMetadata.url}
      />
    )}
  </div>
);

export default AuctionMedia;
