import React from "react";

const AuctionDetails: React.FC<{
  auction: Pick<Queries.AuctionContentFragment, "farmDetails">;
}> = ({ auction }) => (
  <div className="max-w-screen-xl mx-auto px-4 mb-20">
    <h4 className="text-xl font-medium text-clhbid-orange mb-4 lg:text-2xl">
      Description
    </h4>
    <div
      className="auction-farm-details"
      dangerouslySetInnerHTML={{ __html: auction.farmDetails }}
    />
  </div>
);

export default AuctionDetails;
