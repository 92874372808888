import React, { useState } from "react";
import YouTube from "react-youtube";
import { HiBell } from "react-icons/hi";

import Button from "../../../components/Button";
import LongLocalDate from "../../../components/LongLocalDate";

const AuctionIntro: React.FC<{
  auction: Pick<
    Queries.AuctionContentFragment,
    | "auctionDate"
    | "description"
    | "homePictureMetadata"
    | "isLive"
    | "isCompleted"
    | "name"
    | "parcelsUrl"
    | "reminderUrl"
    | "province"
    | "videoUrl"
    | "isReminderAvailable"
  >;
}> = ({ auction }) => {
  const [open, setOpen] = useState(false);

  const videoId =
    auction.videoUrl &&
    auction.videoUrl.replace("https://www.youtube.com/watch?v=", "");

  return (
    <div className="relative py-8 mb-20">
      {auction.homePictureMetadata && (
        <img
          className="block absolute inset-0 w-full h-full object-cover"
          alt={`${auction.name} Parcel`}
          src={auction.homePictureMetadata.url}
        />
      )}
      <div className="absolute inset-0 bg-image-fade" />
      <div className="relative max-w-screen-xl mx-auto px-4">
        <h1 className="text-4xl font-semibold mb-8">
          {auction.name}{" "}
          <span
            className={
              auction.isCompleted ? "text-red-600" : "text-clhbid-orange"
            }
          >
            {auction.isCompleted ? "Sold" : "For Sale"}
          </span>{" "}
          - <span className="auction-province">{auction.province}</span>
        </h1>
        <div className="lg:flex lg:items-start lg:justify-between">
          <div className="pb-8 lg:pb-0 lg:w-7/12 lg:shrink-0 lg:grow-0">
            <h3 className="text-2xl font-semibold mb-6">
              {auction.isCompleted ? "Sold on" : "Selling on"}{" "}
              <LongLocalDate dateAsISOString={auction.auctionDate} />
            </h3>
            <div
              className="auction-description"
              dangerouslySetInnerHTML={{ __html: auction.description }}
            />
            {auction.isReminderAvailable && (
              <Button
                href={auction.reminderUrl}
                state={{ modal: true, noScroll: true }}
                className="w-full flex items-center justify-center lg:w-auto lg:inline-block"
              >
                <HiBell className="inline w-6 mr-2" />
                Set Reminder
              </Button>
            )}
          </div>
          <div className="lg:w-4/12 lg:shrink-0 lg:grow-0">
            {videoId && (
              <>
                <div className="relative bg-white p-4 rounded-md shadow-lg">
                  <button className="relative" onClick={() => setOpen(true)}>
                    <img
                      alt="Auction Video Thumbnail"
                      src={`https://img.youtube.com/vi/${videoId}/sddefault.jpg`}
                    />
                    <svg
                      className="absolute w-24 h-24 top-[calc(50%-48px)] left-[calc(50%-48px)]"
                      x="0px"
                      y="0px"
                      viewBox="0 0 158 110"
                    >
                      <path
                        fill="#f00"
                        d="M154.4,17.5c-1.8-6.7-7.1-12-13.9-13.8C128.2,0.5,79,0.5,79,0.5s-48.3-0.2-60.6,3 c-6.8,1.8-13.3,7.3-15.1,14C0,29.7,0.3,55,0.3,55S0,80.3,3.3,92.5c1.8,6.7,8.4,12.2,15.1,14c12.3,3.3,60.6,3,60.6,3s48.3,0.2,60.6-3 c6.8-1.8,13.1-7.3,14.9-14c3.3-12.1,3.3-37.5,3.3-37.5S157.7,29.7,154.4,17.5z"
                      />
                      <polygon
                        fill="#fff"
                        points="63.9,79.2 103.2,55 63.9,30.8 "
                      />
                    </svg>
                  </button>
                </div>
                <h2 className="mt-2 font-medium text-xl text-center">
                  Click to play video
                </h2>
              </>
            )}
          </div>
        </div>
      </div>
      {open && (
        <div className="flex flex-col justify-center items-center fixed inset-0 z-50 bg-black/75">
          <div className="relative w-full max-w-2xl">
            <YouTube
              className="block absolute w-full h-full"
              containerClassName="w-full h-0 pb-[calc(60%)]"
              videoId={videoId}
              opts={{
                playerVars: {
                  fs: 1,
                  loop: 1,
                  mute: 0,
                  autoplay: 1,
                  controls: 1,
                  modestbranding: 1,
                  rel: 1,
                },
              }}
            />
          </div>
          <button
            className="block border-2 rounded-md border-clhbid-orange text-xl mx-auto text-clhbid-orange mt-8 p-2 hover:bg-clhbid-orange/[0.10] active:bg-clhbid-orange/[0.20] transition-colors"
            onClick={() => setOpen(false)}
          >
            Back To Auction Details
          </button>
        </div>
      )}
    </div>
  );
};

export default AuctionIntro;
