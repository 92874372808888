import { useState } from "react";

import config from "../../clhbid-config";

import usePostMessage from "./use-post-message";

const allowedOrigins = [config.AUCTION_ENGINE_URL, "https://localhost:5001"];

const usePreviewAuction = (): Queries.Auction => {
  const [auction, setAuction] = useState<Queries.Auction>(null);

  usePostMessage(allowedOrigins, (message: string) => {
    try {
      const decodedData = JSON.parse(atob(decodeURIComponent(message)));
      setAuction(decodedData);
    } catch (ex) {
      setAuction(null);
    }
  });

  return auction;
};

export default usePreviewAuction;
