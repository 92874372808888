import React from "react";

import usePreviewAuction from "../hooks/use-preview-auction";

import AuctionContent from "../sections/AuctionContent";

const Preview: React.FC = () => {
  const auction = usePreviewAuction();

  if (!auction) {
    return null;
  }

  return <AuctionContent auction={auction} />;
};

export default Preview;
