import React from "react";
import { Dot } from "pure-react-carousel";

const Navigation: React.FC<{
  thumbnails: ReadonlyArray<{ readonly url: string }>;
  previews: boolean;
}> = ({ thumbnails, previews }) => (
  <div
    className={`flex pt-2 gap-2 flex-wrap ${previews ? "" : "justify-center"}`}
  >
    {thumbnails.map((thumbnail, i) =>
      previews ? (
        <Dot
          slide={i}
          key={thumbnail.url}
          className="w-[calc((100%-32px)*0.2)] border-2 border-gray-700 disabled:border-clhbid-orange"
        >
          <img
            className="w-full"
            src={thumbnail.url}
            alt="Auction Parcel Thumbnail"
          />
        </Dot>
      ) : (
        <Dot
          slide={i}
          key={thumbnail.url}
          className="w-4 h-4 rounded-full bg-white disabled:bg-clhbid-orange"
        />
      )
    )}
  </div>
);

export default Navigation;
