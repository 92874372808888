import React, { useState } from "react";
import { CarouselProvider } from "pure-react-carousel";

import Carousel from "./Carousel";
import Navigation from "./Navigation";

const MediaSlider: React.FC<{
  className?: string;
  pictures: ReadonlyArray<{ readonly url: string }>;
  thumbnails: ReadonlyArray<{ readonly url: string }>;
}> = ({ className, pictures, thumbnails }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={className}>
      <CarouselProvider
        totalSlides={pictures.length}
        naturalSlideHeight={2}
        naturalSlideWidth={3}
      >
        <Carousel
          zoom={true}
          pictures={pictures}
          onClick={() => setOpen(true)}
        />
        <Navigation previews={true} thumbnails={thumbnails} />
        {open && (
          <div className=" flex justify-center items-center fixed inset-0 z-50 bg-black/75">
            <div className="w-full max-w-2xl">
              <Carousel pictures={pictures} zoom={false} />
              <Navigation previews={false} thumbnails={thumbnails} />
              <button
                className="block border-2 rounded-md border-clhbid-orange text-xl mx-auto text-clhbid-orange mt-8 p-2 hover:bg-clhbid-orange/[0.10] active:bg-clhbid-orange/[0.20] transition-colors"
                onClick={() => setOpen(false)}
              >
                Back To Auction Details
              </button>
            </div>
          </div>
        )}
      </CarouselProvider>
    </div>
  );
};

export default MediaSlider;
