import { useCallback, useEffect } from "react";

const usePostMessage = <T>(
  allowedOrigins: string[],
  callback: (data: T) => void
): void => {
  const messageHandler = useCallback(
    (event: MessageEvent<T>) => {
      if (allowedOrigins.indexOf(event.origin) === -1) {
        return;
      }

      if (!event.data) {
        return;
      }

      callback(event.data);
    },
    [allowedOrigins, callback]
  );

  useEffect(() => {
    window.addEventListener("message", messageHandler);
    return () => window.removeEventListener("message", messageHandler);
  }, [messageHandler]);
};

export default usePostMessage;
