import React from "react";

import Button from "../../../components/Button";

const AuctionHeader: React.FC<{
  auction: Pick<Queries.AuctionContentFragment, "name" | "parcelsUrl">;
}> = ({ auction }) => (
  <div className="py-6 bg-clhbid-orange lg:py-8">
    <div className="lg:flex lg:justify-between lg:items-center max-w-screen-xl mx-auto px-4">
      <h2 className="text-3xl mb-4 lg:text-4xl lg:mb-0">{auction.name}</h2>
      <Button
        family="white"
        className="block w-fit mx-auto lg:mx-0"
        external
        href={auction.parcelsUrl}
      >
        View Parcels for Auction
      </Button>
    </div>
  </div>
);

export default AuctionHeader;
