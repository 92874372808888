import React from "react";
import { Slide, Slider, ButtonBack, ButtonNext } from "pure-react-carousel";

const Carousel: React.FC<
  | {
      pictures: ReadonlyArray<{ readonly url: string }>;
      zoom: false;
      onClick?: () => void;
    }
  | {
      pictures: ReadonlyArray<{ readonly url: string }>;
      zoom: true;
      onClick: () => void;
    }
> = ({ pictures, zoom, onClick }) => (
  <div className="relative">
    {zoom && (
      <button
        className="absolute top-2 right-2 z-10 rounded-md bg-gray-700"
        onClick={onClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-9 h-9 cursor-pointer text-white hover:text-clhbid-orange transition-colors"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M3 4a1 1 0 011-1h4a1 1 0 010 2H6.414l2.293 2.293a1 1 0 01-1.414 1.414L5 6.414V8a1 1 0 01-2 0V4zm9 1a1 1 0 110-2h4a1 1 0 011 1v4a1 1 0 11-2 0V6.414l-2.293 2.293a1 1 0 11-1.414-1.414L13.586 5H12zm-9 7a1 1 0 112 0v1.586l2.293-2.293a1 1 0 011.414 1.414L6.414 15H8a1 1 0 110 2H4a1 1 0 01-1-1v-4zm13-1a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 110-2h1.586l-2.293-2.293a1 1 0 011.414-1.414L15 13.586V12a1 1 0 011-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    )}
    <ButtonBack className="absolute top-[calc(50%-24px)] left-2 z-10 rounded-md bg-gray-700 disabled:hidden">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-9 h-9 cursor-pointer text-white hover:text-clhbid-orange transition-colors"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
          clipRule="evenodd"
        />
      </svg>
    </ButtonBack>
    <ButtonNext className="absolute top-[calc(50%-24px)] right-2 z-10 rounded-md bg-gray-700 disabled:hidden">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-9 h-9 cursor-pointer text-white hover:text-clhbid-orange transition-colors"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
          clipRule="evenodd"
        />
      </svg>
    </ButtonNext>
    <Slider classNameTrayWrap="overflow-hidden" classNameTray="flex relative">
      {pictures.map((picture, i) => (
        <Slide
          className="bg-gray-700 relative"
          innerClassName="absolute inset-0 cursor-grab active:cursor-grabbing"
          index={i}
          key={picture.url}
        >
          <img
            className="block object-contain h-full mx-auto"
            src={picture.url}
            alt={`Auction Parcel Media`}
          />
        </Slide>
      ))}
    </Slider>
  </div>
);

export default Carousel;
