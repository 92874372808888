import React from "react";

import Button from "../../../components/Button";
import LongLocalDate from "../../../components/LongLocalDate";

const AuctionFooter: React.FC<{
  auction: Pick<
    Queries.AuctionContentFragment,
    "auctionDate" | "isCompleted" | "parcelsUrl"
  >;
}> = ({ auction }) => (
  <div className="py-4 bg-clhbid-orange lg:py-6">
    <div className="lg:flex lg:justify-between lg:items-center max-w-screen-xl mx-auto px-4">
      <h2 className="text-xl font-medium text-center mb-4 lg:text-2xl lg:mb-0">
        Bidding {auction.isCompleted ? "ended" : "starts"} on{" "}
        <LongLocalDate dateAsISOString={auction.auctionDate} />
      </h2>
      <Button
        family="white"
        external
        href={auction.parcelsUrl}
        className="block w-fit mx-auto lg:mx-0"
      >
        View Parcels for Auction
      </Button>
    </div>
  </div>
);

export default AuctionFooter;
