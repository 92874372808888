import React from "react";
import { graphql } from "gatsby";

import AuctionHeader from "./AuctionHeader";
import AuctionIntro from "./AuctionIntro";
import AuctionMedia from "./AuctionMedia";
import AuctionDetails from "./AuctionDetails";
import AuctionFooter from "./AuctionFooter";

const AuctionContent: React.FC<{ auction: Queries.AuctionContentFragment }> = ({
  auction,
}) => {
  return (
    <>
      <AuctionHeader auction={auction} />
      <AuctionIntro auction={auction} />
      <AuctionMedia auction={auction} />
      <AuctionDetails auction={auction} />
      <AuctionFooter auction={auction} />
    </>
  );
};

export const AuctionContentFragment = graphql`
  fragment AuctionContent on Auction {
    auctionDate
    description
    farmDetails
    rawDescription
    homePictureMetadata {
      url
    }
    isLive
    isCompleted
    isReminderAvailable
    mapMetadata {
      url
    }
    name
    parcelsUrl
    reminderUrl
    pictureMetadata {
      url
    }
    province
    thumbnailMetadata {
      url
    }
    url
    videoUrl
  }
`;

export default AuctionContent;
